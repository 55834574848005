<template>
	<v-layout v-if="treeStructure && selectedFolder" fill-height>
		<v-flex ml-1 mb-5 pb-5 pt-3 fill-height scroll-y class="treeview-scroll-container">
			<v-treeview
				v-if="treeStructure && treeStructure.is_root && treeStructure.fetched"
				:items="treeStructure.folders"
				item-children="folders"
				item-key="id"
				item-text="name"
				activatable
				active-class="treeview-active-folder"
				hoverable
				transition
				return-object
				small
				:load-children="loadFolderChildren"
				:active.sync="activeNodes"
				:open="openedNodes"
				@update:open="onOpenedNodesChanged"
			>
				<template v-slot:label="{ item: folder, open }">
					<v-flex shrink :data-dropzone="`${folder.id}`" :data-draggable="`${folder.id}`" @click="selectFolder(folder)" @contextmenu="doNothing($event)">
						<NodeItem small :simple="true" :vertical="false" :open="open" :value="folder" />
					</v-flex>
				</template>
			</v-treeview>
			<v-progress-circular v-else color="primary" indeterminate />
		</v-flex>
	</v-layout>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'DocumentsTreeview',
	components: {
		NodeItem: () => ({
			component: import('@/components/Documents/Content/NodeItem')
		})
	},
	mixins: [DocumentsManagerModuleGuard],
	props: {
		selectedFolder: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			activeNodes: []
		}
	},
	computed: {
		treeStructure: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		openedNodes: function () {
			return this.treeStructure.openedFolders
		}
	},
	watch: {
		selectedFolder: {
			immediate: true,
			handler: function (newValue, oldValue) {
				this.updateActiveNodes()
				if (!oldValue && newValue) {
					this.updateOpenedNodes()
				}
				this.adjustScrolling()
			}
		},
	},
	mounted: function () {
		this.updateActiveNodes()
	},
	methods: {
		updateActiveNodes: function () {
			if (this.selectedFolder && !this.selectedFolder.is_root) {
				if (!this.activeNodes.map(activeNode => activeNode.id).includes(this.selectedFolder.id)) {
					this.activeNodes.splice(0, this.activeNodes.length, this.selectedFolder)
					this.updateOpenedNodes()
				}
			} else if (this.activeNodes.length > 0) {
				this.activeNodes.splice(0, this.activeNodes.length)
			}
		},
		updateOpenedNodes: function () {
			const shouldBeOpenedNodes = [...this.openedNodes]
			let currentFolder = this.selectedFolder
			while (currentFolder && !currentFolder.is_root) {
				if (!this.openedNodes.map(openedNode => openedNode.id).includes(currentFolder.id)) {
					shouldBeOpenedNodes.push(currentFolder)
				}
				currentFolder = currentFolder.parent
			}
			const result = []
			result.push(
				...shouldBeOpenedNodes.filter(
					shouldBeOpenedNode => (shouldBeOpenedNode.has_sub_folders && !shouldBeOpenedNode.is_empty) || shouldBeOpenedNode.id == this.selectedFolder.id
				)
			)
			this.onOpenedNodesChanged(result)
		},
		onOpenedNodesChanged: function (openedNodes) {
			const openedNodesIds = openedNodes.map(openedNode => openedNode.id)
			this.treeStructure.openedFolders
				.filter(openedFolder => openedFolder?.id && !openedNodesIds.includes(openedFolder.id))
				.forEach(openedFolder => {
					this.service.markFolderIdAsClosed(openedFolder.id)
				})
			openedNodes.forEach(openedNode => {
				if (openedNode?.id) {
					this.service.markFolderIdAsOpened(openedNode.id)
				}
			})
		},
		adjustScrolling: function () {
			this.$nextTick(() => {
				const treeviewContainerElement = document.getElementsByClassName('treeview-scroll-container')[0]
				const treeviewActiveElement = treeviewContainerElement?.getElementsByClassName('treeview-active-folder')[0]
				const treeviewContainerRect = treeviewContainerElement?.getBoundingClientRect()
				const treeviewActiveRect = treeviewActiveElement?.getBoundingClientRect()
				setTimeout(() => {
					if (treeviewContainerElement && treeviewActiveElement) {
						const treeviewContainerElementTop = treeviewContainerElement.scrollTop
						const treeviewContainerElementBottom = treeviewContainerElementTop + treeviewContainerElement.offsetHeight
						if (treeviewActiveElement.offsetTop + treeviewActiveElement.offsetHeight > treeviewContainerElementBottom) {
							treeviewContainerElement.scrollTop = treeviewActiveRect && treeviewContainerRect ? treeviewActiveRect['top'] - treeviewContainerRect['top'] : 0
						}
						treeviewContainerElement.scrollLeft =
							treeviewActiveRect && treeviewContainerRect ? (treeviewActiveRect['left'] - treeviewContainerRect['left']) / 2 : 0
					}
				}, 1000)
			})
		},
		loadFolderChildren: function (folder) {
			const parameters = {
				filters: {
					with_content: true
				},
				fields: ['counter', 'folders', 'folders.counter', 'folders.has_sub_folders', 'has_sub_folders']
			}
			return this.service.getFolder(this.vendorId, folder.hash, parameters, false, false).then(fetchedFolders => {
				if (fetchedFolders) {
					folder.folders = fetchedFolders
				}
			})
		},
		selectFolder: function (folder) {
			this.$emit('selection-changed', folder)
		},
		doNothing: function (event) {
			event.preventDefault()
			event.stopPropagation()
		}
	}
}
</script>

<style>
.treeview-active-folder {
	background-color: rgba(var(--v-primary-transparent), 0.25) !important;
	min-width: max-content;
}
</style>
